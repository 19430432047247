import request from '@/utils/request'

/**
 * 服务站分布管理
 */
export function GoodsFlowMapQuery(params) {
  return request({
    url: '/GoodsFlowMap/query/' + params,
    method: 'get'
  })
}
