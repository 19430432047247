<template>
  <div class="commodityFlow flex pt20px pd1h">
    <div class="">
      <div class="part">
        <itemBg width="69" height="90" title="近12个月物流流向" />
        <div class="part_con">
          <InflowMap id="map" width="69vw" height="90vh" :options="mapData" />
        </div>
      </div>
    </div>
    <div class="">
      <div class="part">
        <itemBg width="29" height="44" title="崇信天气" />

        <div class="part_con">
          <div class="weather_box">
            <div class="tit">实时天气：</div>
            <div v-for="(item, index) in lives" :key="index" class="flex">
              <div class="info">天气：{{ item.weather }}</div>
              <div class="info">温度：{{ item.temperature }}℃</div>
              <div class="info">风向：{{ item.winddirection }}</div>
              <div class="info">风力：{{ item.windpower }}级</div>
              <div class="info">空气湿度：{{ item.humidity }}</div>
            </div>
            <div class="tit">天气预报：</div>
            <div v-for="(item1, index1) in forecasts[0].casts" :key="index1 + 1" class="flex">
              <div class="info">{{ item1.date }}</div>
              <div class="info">{{ item1.dayweather }}~{{ item1.nightweather }}</div>
              <div class="info">{{ item1.daytemp }}℃~{{ item1.nighttemp }}℃</div>
            </div>
          </div>
        </div>
      </div>
      <div class="part mt2h">
        <itemBg width="29" height="44" title="订单量环比分析" />
        <div class="part_con">
          <e-charts id="all" width="29vw" height="44vh" :options="all" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LINE_OPTINO } from '@/utils/eChartsSetOption'
import routeChange from '@/mixins/routeChange'
import itemBg from '@/components/itemBg'
// import Amap from '@/components/map'
import InflowMap from '@/components/InflowMap'
import { GoodsFlowMapQuery } from '@/api/commodityFlow'
import axios from 'axios'
export default {
  name: '',
  components: {
    itemBg,
    InflowMap
  },
  mixins: [routeChange],
  data() {
    return {
      all: JSON.parse(JSON.stringify(LINE_OPTINO)),
      mapData: [],
      forecasts: [{ casts: [] }],
      lives: []
    }
  },
  computed: {},
  created() {},
  mounted() {
    axios
      .get(
        'https://restapi.amap.com/v3/weather/weatherInfo?key=f32830f2b0e9cef08e86d213f130ff71&city=620823&extensions=all'
      )
      .then((response) => {
        if (response.data.status === '1') {
          this.forecasts = response.data.forecasts
        }
      })
    axios
      .get(
        'https://restapi.amap.com/v3/weather/weatherInfo?key=f32830f2b0e9cef08e86d213f130ff71&city=620823&extensions=base'
      )
      .then((response) => {
        if (response.data.status === '1') {
          // console.log(response.data.lives)
          this.lives = response.data.lives
        }
      })
    // 近12个月物流流向
    this.getGoodsFlowMapQuery(6)
    // 订单量环比分析
    this.getGoodsFlowMapQuery(7)
  },
  methods: {
    getGoodsFlowMapQuery(type) {
      GoodsFlowMapQuery(type).then((res) => {
        switch (type) {
          case 6:
            this.mapData = res.twelveMonthWaybillInflow
            break
          case 7:
            this.all.xAxis.axisLabel.interval = 0
            this.all.xAxis.axisLabel.rotate = 45
            this.all.grid.bottom = 120
            // this.all.grid.right = 80
            this.all.grid.left = '10%'
            this.all.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
            this.all.xAxis.axisLabel.textStyle.color = '#fff'

            this.all.series[0] = {
              name: '当月运单数',
              data: res.orderTotalChainAnalysis[0].cn_prev1,
              type: 'line',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(128, 255, 165)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(1, 191, 236)' // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              },
              emphasis: {
                focus: 'series'
              }
            }
            this.all.series[1] = {
              name: '前月运单数',
              data: res.orderTotalChainAnalysis[0].cn_prev2,
              type: 'line',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#1166FF' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#00A2FF' // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              },
              emphasis: {
                focus: 'series'
              }
            }
            this.all.legend.data = ['当月运单数', '前月运单数']
            this.all.xAxis.data = res.orderTotalChainAnalysis[0].NAME
            break
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.weather_box {
  width: 26vw;
  height: 36vh;
  padding: 2vh 1.5vw;
  .tit {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 3vh;
    color: #00eaff;
  }
  .info {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 3.3vh;
  }
}
</style>
